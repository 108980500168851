/* eslint-disable react/no-multi-comp */
import type { ReactElement } from 'react';
import { StrictMode, useEffect } from 'react';
import { CssBaseline, GlobalStyles } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { OryProvider } from '@noah-labs/fe-shared-data-access-auth';
import { AnalyticsProvider } from '@noah-labs/fe-shared-feature-analytics';
import { useAuth, useQueryAuthGuard } from '@noah-labs/fe-shared-feature-auth';
import { LiveChatProvider } from '@noah-labs/fe-shared-feature-help';
import { DatadogProvider } from '@noah-labs/fe-shared-feature-logger';
import { SardineProvider } from '@noah-labs/fe-shared-feature-sardine';
import { SigningProvider } from '@noah-labs/fe-shared-feature-signing';
import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import {
  DialogsProvider,
  ErrorBoundary,
  NavigationProvider,
  snackbarProviderConfig,
  useSearchParams,
} from '@noah-labs/fe-shared-ui-components';
import { getAppType } from '@noah-labs/fe-shared-ui-shared';
import { type PpWC } from '@noah-labs/fe-shared-ui-shared';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { isBrowser } from '@noah-labs/fe-shared-util-shared';
import { logger } from '@noah-labs/shared-logger/browser';
import type { WrapRootElementBrowserArgs, WrapRootElementNodeArgs } from 'gatsby';
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter, StaticRouter, useHistory } from 'react-router-dom';
// import { reportWebVitals } from './reportWebVitals';
import { Seo } from './components/layout/Seo';
import { SubscriptionProvider } from './modules/subscriptions/hooks/SubscriptionProvider';
import { RouterProvider } from './routing/RouterProvider';
import { setModuleConfigs } from './setModuleConfigs';
import { globalStyles, lightTheme } from './theme/themes';
import { isProd, webConfigBrowser } from './webConfigBrowser';
import './polyfills';

// see: node_modules/react-query/types/core/types.d.ts and https://react-query.tanstack.com/guides/important-defaults#_top
const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      retry: false,
    },
    queries: {
      enabled: false,
      retry: false,
      staleTime: 10000,
    },
  },
});

const {
  auth: { oryCustomUrl },
  baseUrl,
  commitHash,
  sardine,
  settings,
} = webConfigBrowser;

/**
 * Configure modules
 */
setModuleConfigs(webConfigBrowser, isProd);

/**
 * This level needs the OryProvider & QueryClientProvider for useAuth
 */
function RootProvidersLevel2({ children }: PpWC): ReactElement {
  const auth = useAuth();
  const user = useUserInitUi();

  // disable queries by default if user is not authed
  useQueryAuthGuard();

  return (
    <SubscriptionProvider>
      <LiveChatProvider>
        <SardineProvider
          clientId={sardine.clientId}
          environment={sardine.environment}
          scriptUrl={sardine.scriptUrl}
        >
          <SigningProvider>
            <AnalyticsProvider
              addSignOutSubscriber={auth.addSignOutSubscriber}
              authStatus={auth.authStatus}
              userCookies={user.data?.userSettingsConsent?.Cookies}
            >
              <DatadogProvider>{children}</DatadogProvider>
            </AnalyticsProvider>
          </SigningProvider>
        </SardineProvider>
      </LiveChatProvider>
    </SubscriptionProvider>
  );
}

/**
 * This level needs the NavigationProvider for useSearchParams which is needed by OryProvider
 */
function RootProvidersLevel1({ children }: PpWC): ReactElement {
  const history = useHistory();

  /**
   * pass the referral code parameter to the Ory provider so it will be added to the Ory urls
   * using useSearchParams causes a re-render here when `updateExitTo` is called because it creates a new state in LSM
   * TODO: give useSearchParams its own context
   */
  const searchParams = useSearchParams();
  const referralCode = searchParams?.get(settings.referralCodeParam);
  let authSp;
  if (referralCode) {
    authSp = {
      [settings.referralCodeParam]: referralCode,
    };
  }

  useEffect(() => {
    const appType = getAppType();
    logger.info('app type:', appType);
  }, []);

  /**
   * In general, lots of components and some hooks / providers need access to the dialogs and snackbars, hence, we should mount those first
   */
  return (
    <SnackbarProvider {...snackbarProviderConfig}>
      <DialogsProvider history={history}>
        <OryProvider appUrl={baseUrl} oryCustomUrl={oryCustomUrl} searchParams={authSp}>
          <QueryClientProvider client={queryClient}>
            <RootProvidersLevel2>{children}</RootProvidersLevel2>
          </QueryClientProvider>
        </OryProvider>
      </DialogsProvider>
    </SnackbarProvider>
  );
}

/**
 * ErrorBoundary needs ThemeProvider but otherwise should be uppermost in the tree
 */
function RootProviders({ children }: PpWC): ReactElement {
  logger.highlight(`Build: ${commitHash}`);
  /**
   * Set the ?search string in initialState of NavigationProvider so that they are persisted after first load
   * Use window.location.search instead of useLocation to prevent rerenders - we only care about the first load here
   */
  const search = isBrowser() ? window.location.search : '';

  return (
    <StrictMode>
      <NavigationProvider initialExitTo={walletRoutes().base.path} initialSearch={search}>
        <ThemeProvider theme={lightTheme}>
          {/* Keeping CssBaseline & GlobalStyles right after ThemeProvider and before ErrorBoundary, ensures correct styles are applied to the Error scenes */}
          <CssBaseline />
          <GlobalStyles styles={globalStyles} />
          <ErrorBoundary>
            <RootProvidersLevel1>
              {children}
              <Seo />
              <ReactQueryDevtools initialIsOpen={false} />
            </RootProvidersLevel1>
          </ErrorBoundary>
        </ThemeProvider>
      </NavigationProvider>
    </StrictMode>
  );
}

/**
 * Note that React Hooks can not be used in either RootProvidersBrowser or RootProvidersSSR
 */
export function RootProvidersBrowser({ element }: WrapRootElementBrowserArgs): ReactElement {
  return (
    <BrowserRouter>
      <RouterProvider>
        <RootProviders>{element}</RootProviders>
      </RouterProvider>
    </BrowserRouter>
  );
}

export function RootProvidersSSR({ element }: WrapRootElementNodeArgs): ReactElement {
  return (
    <StaticRouter>
      <RouterProvider>
        <RootProviders>{element}</RootProviders>
      </RouterProvider>
    </StaticRouter>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
